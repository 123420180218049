import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { media } from '../../utils/media-queries';
import { useClientSide } from '../../hooks/useClientSide';

const HeaderSubPage = ({ headline, className }) => {
  const isClient = useClientSide();
  const ref = useRef(null);
  const anim = useRef();

  useEffect(() => {
    if (isClient) gsap.registerPlugin(ScrollTrigger);

    anim.current = gsap.to(ref.current, {
      // y: 350,
      opacity: 0,
      scrollTrigger: {
        scrub: true,
        end: '+=350',
      },
    });

    return () => {
      anim.current?.scrollTrigger?.kill();
      anim.current?.kill();
    };
  }, [isClient]);

  return (
    <StyledHeaderSubPage className={`grid ${className}`}>
      <motion.h1 className="hl" ref={ref}>
        <span dangerouslySetInnerHTML={{ __html: headline }} />
      </motion.h1>
    </StyledHeaderSubPage>
  );
};

const StyledHeaderSubPage = styled.section`
  height: 66vh;
  margin-left: calc(var(--sidebar-width) + var(--sp-1));
  margin-right: var(--sp-1-8);
  align-items: center;
  position: relative;

  h1 {
    z-index: -1;
    height: 100%;
    display: flex;
    align-items: center;

    span {
      position: fixed;
      margin-right: var(--sp-1-8);
    }
  }

  &.careers {
    .hl {
      color: var(--black);
    }
  }

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);

    h1 {
      grid-column: 6 / 13;
    }
  }

  @media ${media.XL} {
    h1 {
      span {
        margin-right: 20vw;
      }
    }
  }
`;

HeaderSubPage.propTypes = {
  headline: PropTypes.string,
  className: PropTypes.string,
};

export default HeaderSubPage;
