import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import SEO from '../components/organisms/seo';
import HeaderSubPage from '../components/molecules/headerSubPage';
import { ease } from '../utils/easing';
import RichText from '../components/atoms/richtext';
import { media } from '../utils/media-queries';

const PrivacyPolicyPage = ({ data }) => {
  const { title, _rawContent } = data.privacy.nodes[0];

  return (
    <>
      <SEO siteTitle="Research" />
      <HeaderSubPage headline={title} />
      <StyledResearch
        initial={{ y: 500 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.9, ease: ease.inOutSnap }}
      >
        <section className="grid content-wrapper">
          <div className="content">
            <RichText blocks={_rawContent} />
          </div>
        </section>
      </StyledResearch>
    </>
  );
};

const StyledResearch = styled(motion.article)`
  background: var(--white);
  margin-left: var(--sidebar-width);

  .content-wrapper {
    padding-top: var(--sp-3);
    margin-left: var(--sp-1);
    margin-right: var(--sp-1-8);

    .content {
      p {
        padding-bottom: var(--sp-1-3);
      }
    }
  }

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);

    .content-wrapper {
      padding-top: var(--sp-8);
      margin-left: 0;
    }

    .content {
      grid-column: 6 / 12;
    }
  }

  @media ${media.XL} {
    .content {
      grid-column: 6 / 10;
    }
  }
`;

export const query = graphql`
  query Privacy {
    privacy: allSanityPrivacypolicy {
      nodes {
        title
        _rawContent
      }
    }
  }
`;

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object,
};

export default PrivacyPolicyPage;
